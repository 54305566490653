import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import dayjs from 'dayjs'

import Storage from '@octadesk-tech/storage'

import { STATUS_CODE } from '@/common/helpers/enums/http-status-codes'

import store from '@/store'

const isTokenExpired = (): boolean => {
  const token = Storage.getItem('userToken') as string

  if (!token) return false

  try {
    const [, payload] = token.split('.')

    const { exp } = JSON.parse(atob(payload))

    return dayjs().unix() > exp
  } catch {
    return true
  }
}

const logout = () => {
  store.dispatch('logout', {
    logoutReason: 'expiredSession'
  })
}

export const applyInterceptors = (client: AxiosInstance): AxiosInstance => {
  client.interceptors.request.use(async (config: AxiosRequestConfig) => {
    if (isTokenExpired()) {
      logout()
    }

    return config
  })

  client.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: any) => {
      if (error.response?.status === STATUS_CODE.UNAUTHORIZED) {
        logout()
      }
    }
  )

  return client
}
